import React from "react"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import {
  Grid,
  GridListTileBar,
  GridListTile,
  GridList,
  isWidthUp,
  withWidth,
} from "@material-ui/core"
import LoadMoreButton from "./load-more-button"
import * as LayoutUtils from "../helpers/layout-utils"
import {
  InstanceArtistLabel,
  InstanceReleaseEntry,
  InstanceReleaseList,
} from "../helpers/discogs-types"
import Img from "gatsby-image"

interface GridLayoutProps {
  type: string
  items: InstanceReleaseList
  nextPage: string
  width: Breakpoint
  loadMoreHandler: () => Promise<void>
  loadingPage: boolean
  loadItemHandler: (id: string) => Promise<void>
  closeDialogHandler: () => void
  selectedItem: InstanceReleaseEntry | null
  showInstanceLink?: boolean
  isLoadingItem?: boolean
  useFullDialog?: boolean
}

const GridLayout = (props: GridLayoutProps) => {
  const cols = getGridListCols(props.width)
  const classes = LayoutUtils.useStyles()

  const items = props.items.map((release, idx) => {
    const data = release.data
    const releaseId = release.id
    const title = release.title
    let artists = ""
    let artistsList
    if (data.artists) {
      artistsList = data.artists.map((artist: InstanceArtistLabel) => {
        return artist.name
      })
      artists = artistsList.join(", ")
    }

    let image
    if (release.gatsbyImage) {
      const { gatsbyImage } = release
      image = <Img fluid={gatsbyImage.childImageSharp.fluid} />
    } else {
      image = <img src={data.imageUrl} alt={title} />
    }

    return (
      <GridListTile
        key={idx}
        cols={1}
        className={classes.gridItem}
        onClick={() => {
          props.loadItemHandler(releaseId)
        }}
      >
        {image}
        <GridListTileBar title={title} subtitle={artists} />
      </GridListTile>
    )
  })

  return (
    <div>
      <GridList cellHeight={200} cols={cols}>
        {items}
      </GridList>
      <Grid container>
        <Grid item xs={12} className={classes.loadBtnRow}>
          <LoadMoreButton
            hasNextPage={props.nextPage.length > 0}
            isLoading={props.loadingPage}
            clickHandler={props.loadMoreHandler}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const getGridListCols = (width: Breakpoint) => {
  if (isWidthUp("lg", width)) {
    return 5
  }
  if (isWidthUp("sm", width)) {
    return 4
  }

  return 2
}

export default withWidth()(GridLayout)
