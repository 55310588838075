import React from "react"
import {
  Drawer,
  Typography,
  List,
  ListItemText,
  ListItem,
  withWidth,
  isWidthUp,
  ListItemIcon,
  Button,
  IconButton,
  Divider,
  Grid,
  isWidthDown,
} from "@material-ui/core"
import * as LayoutUtils from "../helpers/layout-utils"
import CloseIcon from "@material-ui/icons/Close"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import RadioOutlinedIcon from "@material-ui/icons/RadioOutlined"
import AlbumIcon from "@material-ui/icons/Album"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
// import { Link as Link } from "react-router-dom";
import { Link } from "gatsby"
import {
  InstanceArtistLabel,
  InstanceReleaseEntry,
} from "../helpers/discogs-types"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

interface ItemDrawerProps {
  type: string
  width: Breakpoint
  item: InstanceReleaseEntry | null
  closeHandler: () => void
  useInstanceLink?: boolean
}

const ItemDrawer = (props: ItemDrawerProps) => {
  const isOpen = props.item !== null

  const drawerClass = isWidthUp("sm", props.width)
    ? "itemDrawerRight"
    : "itemDrawerBottom"
  const anchor = isWidthDown("sm", props.width, false) ? "bottom" : "right"

  const classes: Record<
    LayoutUtils.DiscogsClasses,
    any
  > = LayoutUtils.useStyles()

  const title = props.item ? props.item.title : ""
  const data: { [key: string]: any } = props.item ? props.item.data : {}
  let coverImg = null
  if (data.imageUrl) {
    coverImg = <img src={data.imageUrl} alt={title} />
  }

  let year = null
  if (data.year) {
    const yearUrl = "/collection/" + props.type + "/year/" + data.year
    year = (
      <>
        <ListItem>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <Typography variant="overline">Released</Typography>
          <Link
            to={yearUrl}
            className={classes.yearBtn}
            onClick={props.closeHandler}
          >
            <Button
              variant="outlined"
              aria-label={`View records from ${data.year}`}
            >
              {data.year}
            </Button>
          </Link>
        </ListItem>
        <Divider />
      </>
    )
  }

  let artists = null
  let opts: ArtistSectionOpts
  if (data.artists) {
    opts = {
      artists: data.artists,
      classes,
      icon: <AccountCircleOutlinedIcon />,
      type: "artists",
      closeHandler: props.closeHandler,
    }
    artists = artistSection(opts)
  }

  let labels = null
  if (data.labels) {
    opts = {
      artists: data.labels,
      classes,
      icon: <RadioOutlinedIcon />,
      type: "labels",
      closeHandler: props.closeHandler,
    }
    labels = artistSection(opts)
  }

  let format = null
  if (data.format && Array.isArray(data.format)) {
    format = data.format.join(", ")
  }

  let notes = null
  if (data.notes && data.notes.trim().length > 0) {
    notes = <span>&nbsp;&middot;&nbsp;{data.notes}</span>
  }

  let info = null
  if (format && notes) {
    info = (
      <>
        <ListItem>
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <Typography variant="overline">Information</Typography>
        </ListItem>
        <ListItem>
          {format}
          {notes}
        </ListItem>
        <Divider />
      </>
    )
  }

  let instancesLink = null
  if (props.item && props.useInstanceLink) {
    const instancesUrl = "/collection/" + props.type + "/" + props.item.id
    instancesLink = (
      <>
        <Divider />
        <ListItem
          button
          component={Link}
          to={instancesUrl}
          state={{ name: title }}
        >
          <ListItemIcon>
            <AlbumIcon />
          </ListItemIcon>
          <ListItemText
            primary="View Records"
            primaryTypographyProps={{ variant: "button" }}
          />
        </ListItem>
      </>
    )
  }

  return (
    <Drawer anchor={anchor} onClose={props.closeHandler} open={isOpen}>
      <List className={classes[drawerClass]}>
        <ListItem>
          <ListItemIcon>
            <IconButton onClick={props.closeHandler} aria-label="Close details">
              <CloseIcon />
            </IconButton>
          </ListItemIcon>
          <Typography variant="h5">{title}</Typography>
        </ListItem>
        <Divider />
        {year}
        <ListItem>{coverImg}</ListItem>
        <Divider />
        {info}
        {artists}
        <Divider />
        {labels}
        {instancesLink}
      </List>
    </Drawer>
  )
}

interface ArtistSectionOpts {
  artists: InstanceArtistLabel[]
  type: string
  icon: JSX.Element
  classes: Record<LayoutUtils.DiscogsClasses, any>
  closeHandler: () => void
}

const artistSection = (opts: ArtistSectionOpts) => {
  const identifier = opts.type === "labels" ? "Label" : "Artist"
  // const names = Object.entries(opts.artists as { [key: string]: string }).map(([id, name], key) => {
  const names = opts.artists.map(({ id, name }, key) => {
    const url = "/collection/" + opts.type + "/" + identifier + "-" + id
    return (
      <Link
        to={url}
        state={{ name }}
        className={opts.classes.artistButton}
        onClick={opts.closeHandler}
        key={key}
      >
        <Button variant="outlined" aria-label={`View ${name} records`}>
          {name}
        </Button>
      </Link>
    )
  })

  return (
    <>
      <ListItem>
        <ListItemIcon>{opts.icon}</ListItemIcon>
        <Typography variant="overline">
          {opts.type.charAt(0).toUpperCase() + opts.type.substring(1)}
        </Typography>
      </ListItem>
      <ListItem>
        <Grid container>
          <Grid item xs={12}>
            {names}
          </Grid>
        </Grid>
      </ListItem>
    </>
  )
}

export default withWidth()(ItemDrawer)
